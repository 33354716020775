import './App.css';
import { useState } from 'react';
import Axios from 'axios';
import ChosenOne from './ChosenOne';


function App() {
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [path, setPath] = useState('');

  const chooseCode = () => {
    console.log(process.env.REACT_APP_WICHTEL_API_URL + "/wichteln/choose");
    Axios.put(process.env.REACT_APP_WICHTEL_API_URL + "/wichteln/choose", {
      code: code,
      groupID: window.location.pathname.substring(1)
    })
    .then((res) => {
  
      setName(res.data.name);
      setPath(res.data.imgpath);
      console.log(res.data);
      console.log(name);
      console.log(path);
    }).catch((err) => { console.log(err) });
  }

  return (
    <div className="App">
      <div>
        <img className="santa-gif" src="/Animation - 1702836178857.gif" alt="gif broki :("></img>
        <input type="text" placeholder='code' onChange={(e) => {setCode(e.target.value)}}></input>
        <button onClick={chooseCode}>Choose Code</button>
      </div>
      <div>
        <ChosenOne name={name} path={path} />
      </div>
    </div>
  );
}

export default App;
