function ChosenOne(props) {
    
    if(props.name) {
        console.log("imgpath: " + props.path);
        return (
            <div>
                <img className="choosenImg" src={props.path} alt="schönes Bild"></img>
            <h1 className="revealName">Du hast {props.name} gezogen</h1>
            
            </div>
        );
    } else {
        return null;
    }

}

export default ChosenOne;